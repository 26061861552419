<template>
  <div class="messageForm">
    <div class="title">
      <span>留言</span>
      <span
        @click="handleClose"
        class="el-icon-close"
        style="cursor: pointer"
      ></span>
    </div>
    <div class="main">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
      >
        <el-form-item label="联系人" prop="contactName">
          <el-input v-model="ruleForm.contactName"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="contactPhone">
          <el-input v-model="ruleForm.contactPhone"></el-input>
        </el-form-item>
        <el-form-item label="企业名称" prop="companyName">
          <el-input v-model="ruleForm.companyName"></el-input>
        </el-form-item>
        <el-form-item label="地址信息" prop="address">
          <el-input v-model="ruleForm.address"></el-input>
        </el-form-item>

        <el-form-item label="留言信息" prop="feedbackMessage">
          <el-input
            type="textarea"
            v-model="ruleForm.feedbackMessage"
          ></el-input>
        </el-form-item>

        <el-form-item style="text-align: right">
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确定</el-button
          >
          <el-button @click.stop="hanldeCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { leaveMessage } from "@/api/fetch";
import { isvalidPhone } from "@/utils/common";
import Qs from "qs";
export default {
  data() {
    const validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入电话号码"));
      } else if (!isvalidPhone(value)) {
        callback(new Error("请输入正确的11位手机号码"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        contactName: "",
        contactPhone: "",
        companyName: "",
        feedbackMessage: "",
        address: "",
      },
      rules: {
        contactName: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        contactPhone: [{ required: true, validator: validPhone }],
        companyName: [
          { required: false, message: "请输入企业名称", trigger: "blur" },
        ],
        feedbackMessage: [
          { required: false, message: "请填写留言信息", trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  methods: {
    handleClose() {
      this.$emit("hidMsg");
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const prams = Qs.stringify(this.ruleForm);
          leaveMessage(prams)
            .then((res) => {
              this.$message.success("留言成功！");
              this.resetForm("ruleForm");
              this.$emit("hidMsg");
            })
            .catch((e) => {
              console.log(e, 999);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    hanldeCancel() {
      this.resetForm("ruleForm");
    },
  },
};
</script>
 
<style lang="less">
.messageForm {
  width: 450px;
  height: 450px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  position: fixed;
  bottom: 25px;
  right: 100px;
  z-index: 100;
  background: white;
  border-radius: 9px;
  .title {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #eee;
  }
  .main {
    padding: 20px;
  }
}
</style> 